@if (!popupMode()) {
  <div class="p-16 cursor-pointer flex-column" flexGap="12px" (click)="openImage()">
    <div class="flex-row" flexLayoutAlign="start center" flexGap="20px">
      <svg class="svg-24 title-color" svgIcon="utility-edit" />
      <div class="flex">
        <div class="mb-4 flex-row" flexLayoutAlign="start center">
          <div class="labels-label-s greyscale-light-text">{{ getDate(notification().createdAt) }}</div>
        </div>

        @if (showAllDetails()) {
          <div class="mb-4 flex-row" flexGap="8px">
            <svg class="svg-16" svgIcon="other-tasks" />
            <div class="labels-label-s greyscale-light-text">
              <span class="labels-label-s greyscale-darkest-grey">{{ notification().payload.projectImage.name }}</span>
            </div>
          </div>
        }

        <div class="flex-row">
          <div class="labels-label-l greyscale-darkest-grey">
            <strong>{{ notification().payload.source.name }}</strong
            >{{ notifInfo() }}
          </div>
        </div>
      </div>
      <div class="flex-column" flexGap="10px">
        @if (!notification().seen) {
          <div flexLayoutAlign="center center" class="not-seen mx-8"></div>
        }
        @if (unseenCount() > 0) {
          <div flexLayoutAlign="center center" class="not-seen-count mx-8">
            {{ unseenCount() }}
          </div>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="cursor-pointer flex-row" flexGap="20px" (click)="openImage()">
    <svg class="svg-24 title-color" svgIcon="utility-edit" />
    <div class="flex">
      <div class="mb-8 flex-row" flexLayoutAlign="start center" flexGap="8px">
        <div class="labels-label-s greyscale-darkest-grey">{{ notification().payload.projectImage.name }}</div>
      </div>
      <div class="flex-row">
        <div class="labels-label-l greyscale-darkest-grey flex">
          <strong>{{ notification().payload.source.name }}</strong
          >{{ notifInfo() }}
        </div>
      </div>
    </div>
    <svg
      matTooltip="Clear"
      matTooltipClass="icon-tooltip"
      class="svg-24"
      svgIcon="basic-close"
      (click)="onDeleteNotification($event)"
    />
  </div>
}
