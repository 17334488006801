import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import {
  FlexGapDirective,
  SvgIconDirective,
  FlexLayoutAlignDirective,
  NotificationProjectImageUpdated,
} from 'ngx-q360-lib';
import { MatTooltip } from '@angular/material/tooltip';
import {
  getNotificationDate,
  getProjectImageFieldInfo,
} from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { MarkNotificationAsRead } from '@app/store/notification/notification.actions';

@Component({
  selector: 'app-project-image-updated',
  templateUrl: './project-image-updated.component.html',
  styleUrls: ['./project-image-updated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexGapDirective, FlexLayoutAlignDirective, MatTooltip, SvgIconDirective],
})
export class ProjectImageUpdatedComponent {
  protected readonly getDate = getNotificationDate;
  private store = inject(Store);
  private router = inject(Router);

  deleteNotification = output<string>();
  clickNotification = output<void>();

  notification = input.required<NotificationProjectImageUpdated>();
  popupMode = input<boolean>(false);
  unseenCount = input(0);
  showAllDetails = input(true);

  notifInfo = computed(() => getProjectImageFieldInfo(this.notification()));

  openImage() {
    this.store.dispatch(new MarkNotificationAsRead(this.notification()));
    void this.router.navigate([
      `/pages/project/${this.notification().payload.projectImage.projectId}/image-analytics/gallery//${this.notification().payload.projectImage.id}`,
    ]);
    this.clickNotification.emit();
  }

  onDeleteNotification(event: MouseEvent) {
    event.stopPropagation();
    this.deleteNotification.emit(this.notification().id);
  }
}
